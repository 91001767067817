import React from 'react';
import GladlySidekick from './GladlySidekick';
//import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { useMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
//import SignIn from "./components/Authorization/SignIn";
import Card from "react-bootstrap/Card";
//import { Router } from 'react-router-dom';

function signOutClickHandler(instance) {
    instance.logoutPopup();
}


function SignOutButton() {
    // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider
    const { instance } = useMsal();

    return (
        <button onClick={() => signOutClickHandler(instance)}>Sign Out</button>
    );
}

export default function App() {
    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <center id="signin-page">
                    <Card className="signin-card">
                        <Card.Body>
                            <center>
                                <h1>Welcome</h1>
                                <p>You are signed into the OPT QA-1 application!</p>
                                <GladlySidekick />
                                <SignOutButton />
                            </center>
                        </Card.Body>
                    </Card>
                </center>
            </MsalAuthenticationTemplate>
        </>
    );
}
