import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
const msalInstance = new PublicClientApplication(msalConfig);

root.render(
    <MsalProvider instance={msalInstance}>
        <App />
    </MsalProvider>
);